<template>
  <div>
    {{ typeRobe }}
  </div>
</template>
  
  <script>
  import { mapGetters } from 'vuex';
  export default {
      props: {object: {type: Object, required: true}},
      data(){
          return  {
  
          }
      },
      methods: {
         
      },
      computed: {
          ...mapGetters({
            typeRobes: 'identification/typeRobes'
          }),
          typeRobe() {
            let t = this.typeRobes.find(item => item.uid === this.object.type)
            if(t) return t.libelle
            return '-'
          }
      }
  }
  </script>
  
  <style>
  
  </style>